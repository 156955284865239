import React, {useEffect, useState} from "react";
import {findDOMNode} from "react-dom";
import {useSelector} from "react-redux";
import {css} from "styled-components";
import {LoadingDialog, Modal} from "@kepler.gl/components";
import {SubmitHandler, useForm} from "react-hook-form";
import "./ConfigureVisualizationModal.scss";

import {VisualizationParameters, VisualizationParametersValidation} from "../models/VisualizationParameters";
import {closeVisualizationConfigureModal, selectVisualizationConfigModalState} from "../store/VisualizationSlice";
import {useAppDispatch} from "../../../hooks";
import {Checkbox} from "../../../components/form/Checkbox";
import {ErrorMessage} from "../../../components/form/ErrorMessage";
import {colorSelectedLayers, loadPrediction, splitSelectedLayers} from "../../../actions.js"
import {selectSelectedCityName} from "../../../components/user/userSlice";


export const ConfigureVisualizationModal = (props: any) => {
    const {rootNode} = props;
    const isConfigModalOpen = useSelector(selectVisualizationConfigModalState)
    const dispatch = useAppDispatch();
    const datasets = useSelector((state: any) => state.demo.keplerGl.map.visState.datasets);
    const isMapLoading = useSelector((state: any) => state.demo.app.isMapLoading);
    const originalLayers = useSelector((state: any) => state.multipleSimulations.originalLayers);
    const selectedCityName = useSelector(selectSelectedCityName);
    const [isOnSubmit, setIsOnSubmit] = useState(false);
    const [submitData, setSubmitData] = useState(null);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm<VisualizationParameters>()

    const DefaultStyle = css`max-width: 600px;`;

    const BASELINE_DATASET_ID = selectedCityName;
    const BASELINE_DATASET_LABEL = "Baseline prediction";
    const BASELINE_OPTIONS = { id: BASELINE_DATASET_ID, dayType: "weekend", name: BASELINE_DATASET_LABEL };
    const BASELINE_ID = `prediction_${BASELINE_DATASET_ID}_${BASELINE_OPTIONS.dayType}`;

    const onCancel = () => {
        dispatch(closeVisualizationConfigureModal());
        setIsOnSubmit(false);
    }

    useEffect(() => {
        if (isOnSubmit && !isMapLoading && originalLayers[BASELINE_ID]) {
            for (const datasetId of submitData.datasets) {
              dispatch(splitSelectedLayers(submitData.parameters, datasetId, submitData.datasets));
              dispatch(colorSelectedLayers(submitData.parameters, datasetId));
            }
            dispatch(closeVisualizationConfigureModal());
            setIsOnSubmit(false);
        }
    }, [isOnSubmit, isMapLoading, originalLayers]);

    const onSubmit: SubmitHandler<VisualizationParameters> = async (submitData: VisualizationParameters) => {
        if (typeof submitData.datasets === "string") {
            submitData.datasets = [submitData.datasets];
        }

        // Load the baseline dataset if selected
        if (submitData.datasets.includes(BASELINE_ID)) {
            dispatch(loadPrediction(BASELINE_OPTIONS));
            setSubmitData(submitData);
            setIsOnSubmit(true);
        } else {
            for (const datasetId of submitData.datasets) {
              dispatch(splitSelectedLayers(submitData.parameters, datasetId, submitData.datasets));
              dispatch(colorSelectedLayers(submitData.parameters, datasetId));
            }
            dispatch(closeVisualizationConfigureModal());
        }
    }

    if (!rootNode) return null;

    const dataset_keys = Object.keys(datasets);

    if (dataset_keys.length !== 0) {
        const firstDataset = datasets[dataset_keys[0]];
        const fields = firstDataset.fields.filter((field) => !["_geojson", "time_window", "osm_id"].includes(field.name));

        const fieldCheckboxes = fields.map((field) => (
          <Checkbox
            key={field.id}
            showErrors={false}
            name="parameters"
            value={field.id}
            label={field.id}
            validation={VisualizationParametersValidation.parameters}
            errors={errors}
            register={register}
          />
        ));

        const datasetCheckboxes = dataset_keys.map((datasetId) => (
          <Checkbox
            key={datasetId}
            showErrors={false}
            name="datasets"
            value={datasetId}
            label={datasets[datasetId].label}
            validation={VisualizationParametersValidation.datasets}
            errors={errors}
            register={register}
          />
        ));

        // Adds default baseline dataset
        if (!(BASELINE_ID in datasets)) {
            datasetCheckboxes.push(
              <Checkbox
                key={BASELINE_DATASET_ID}
                showErrors={false}
                name="datasets"
                value={BASELINE_ID}
                label={BASELINE_DATASET_LABEL}
                validation={VisualizationParametersValidation.datasets}
                errors={errors}
                register={register}
              />
            )
        }

        return (
            <Modal
              title="Configure Visualization"
              parentSelector={() => findDOMNode(rootNode) as HTMLElement}
              isOpen={isConfigModalOpen}
              theme={null}
              cssStyle={DefaultStyle}
              onCancel={onCancel}
            >
                {isMapLoading ? (
                    <LoadingDialog size={64}/>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} onReset={onCancel} className="citynexus-form">
                        <h4>
                            Select parameters to be visualised
                            <ErrorMessage errors={errors} name="parameters"/>
                        </h4>
                        <div className="form-group">
                            <div className="form-control">
                                {fieldCheckboxes}
                            </div>
                        </div>
                        <h4>
                            Select which datasets the parameters should be visualised from
                            <ErrorMessage errors={errors} name="datasets"/>
                        </h4>
                        <div className="form-group">
                            <div className="form-control">
                                {datasetCheckboxes}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-actions">
                                <input className="button" type="reset" value="Cancel"/>
                                <input className="button" type="submit" value="Save"/>
                            </div>
                        </div>
                    </form>
                )}
            </Modal>
        );
    }
};