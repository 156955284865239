import { createSlice} from "@reduxjs/toolkit";

export const visualizationSlice = createSlice({
    name: "visualization",
    initialState: {
        visualizationConfigModalIsOpen: false,
        inputs: {},
        error: null,
    },
    reducers: {
        openVisualizationConfigureModal: (state) => {
            state.visualizationConfigModalIsOpen = true;
            return state;
        },
        closeVisualizationConfigureModal: (state) => {
            state.visualizationConfigModalIsOpen = false;
            return state;
        },
    }
})

export const {
    openVisualizationConfigureModal,
    closeVisualizationConfigureModal
} = visualizationSlice.actions;

export default visualizationSlice.reducer;

export const selectVisualizationConfigModalState = state => state.visualization.visualizationConfigModalIsOpen;