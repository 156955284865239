import {createSlice} from "@reduxjs/toolkit";

type ProjectType = {
    project: string;
    paid: boolean;
};

const defaultProjectType: ProjectType = {
    project: 'citynexus',
    paid: false
};

const possibleRoles = ["citynexus-free", "citynexus-paid", "immerseon-free", "immerseon-paid"]

export const roleToProjectName = {
    'immerseon': 'Immerseon',
    'citynexus': 'CityNexus',
}

export const userSlice = createSlice({
    name: "user",
    initialState: {
        data: null,
        isAuthenticated: false,
        isLoading: true,
        hasChangedMap: false,
        isUserLoggingOff: false,
        projectTypes: [defaultProjectType],
        selectedProjectType: 0,
        availableCities: ["copenhagen"],
        selectedCityType: 0,
        isProjectImmerseon: defaultProjectType.project === 'immerseon',
    },
    reducers: {
        startLoading: (state, action) => {
            return {...state, data: null, isAuthenticated: false, isLoading: true};
        },
        login: (state, action) => {
            let projectTypes = [];
            const userAvailableProjects = action.payload.profile.roles?.filter(role => possibleRoles.includes(role));
            if (userAvailableProjects?.length > 0) {
                for (const role of userAvailableProjects) {
                    const [project, plan] = role.split("-");
                    projectTypes.push({
                        "project": project,
                        "paid": plan === 'paid'
                    });
                }
            } else {
                projectTypes.push(defaultProjectType);
            }

            // Determine the selected project type based on the current URL
            const url = window.location.href; // Get the current URL
            let selectedProjectTypeIndex: number; // Default to the first project type if no match is found

            if (url.includes('immerseon')) {
                selectedProjectTypeIndex = projectTypes.findIndex(p => p.project === 'immerseon');
            } else {
                selectedProjectTypeIndex = projectTypes.findIndex(p => p.project === 'citynexus');
            }

            return {
                ...state,
                data: action.payload,
                isAuthenticated: true,
                isLoading: false,
                projectTypes: projectTypes,
                selectedProjectType: selectedProjectTypeIndex,
                isProjectImmerseon: projectTypes[selectedProjectTypeIndex].project === 'immerseon'
            };
        },
        logout: (state, action) => {
            return {
                ...state,
                data: null,
                projectTypes: [defaultProjectType],
                selectedProjectType: 0,
                selectedCityType: 0,
                availableCities: ["copenhagen"],
                isAuthenticated: false,
                isLoading: false
            };
        },
        setHasChangedMap: (state, action) => {
            return {...state, hasChangedMap: action.payload};
        },
        setSelectedCityType: (state, action) => {
            return {
                ...state,
                selectedCityType: action.payload,
            };
        },
        setAvailableCities: (state, action) => {
            return {
                ...state,
                availableCities: action.payload,
            };
        },
        loggingOff: (state, action) => {
            return {...state, isUserLoggingOff: true};
        },
    }
})

export const {
    login,
    logout,
    startLoading,
    setHasChangedMap,
    setSelectedCityType,
    setAvailableCities,
    loggingOff
} = userSlice.actions;

export default userSlice.reducer;

export const selectUserToken = state => state.user.data?.access_token;
export const selectUsername = state => state.user.data?.profile?.preferred_username ?? '';
export const selectProjectTypes = state => state.user.projectTypes;
export const selectAuthenticated = state => state.user.isAuthenticated;
export const selectIsLoading = state => state.user.isLoading;
export const selectHasChangedMap = state => state.user.hasChangedMap;
export const selectCurrentScenarioId = state => state?.demo?.keplerGl?.map?.visState?.changes?.scenarioId;
export const selectUserLoggingOff = state => state.user.isUserLoggingOff;
export const selectIsProjectImmerseon = state => state.user.isProjectImmerseon;
export const selectSelectedProjectTypeFormattedString = state => roleToProjectName[state.user.projectTypes[state.user.selectedProjectType].project];
export const selectSelectedProjectTypeString = state => state.user.projectTypes[state.user.selectedProjectType].project;
export const selectAvailableCities = state => state.user.availableCities;
export const selectSelectedCityName = state => state.user.availableCities[state.user.selectedCityType];
export const selectSelectedCityType = state => state.user.selectedCityType;
export const selectScenarioId = state => state.demo.undoRedo.changes.scenarioId;

export const isDefaultScenario = state => {
    const scenarioId = selectScenarioId(state);
    const { availableCities, projectTypes } = state.user;

    const isAvailableCity = availableCities.some(city => city === scenarioId);
    const isProjectType = projectTypes.some(type => type.project === scenarioId);

    return isAvailableCity || isProjectType;
};