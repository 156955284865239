import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {openVisualizationConfigureModal} from "./store/VisualizationSlice";

export const ConfigureVisualizationButton = () => {
    const dispatch = useDispatch();
    const visState = useSelector((state: any) => state?.demo?.keplerGl?.map?.visState);
    const disabled = useSelector((state: any) =>
        "road_network" in state.demo.keplerGl.map.visState.datasets);

    const onClick = async () => {
        try {
            dispatch(openVisualizationConfigureModal())
        } catch (err) {
            console.error('Encountered an error: ', err)
        }
    };

     if (!disabled && visState && visState.layers.length > 0) {
        return (
            <button onClick={onClick} title={"Configure Visualization"}>
                Configure Visualization
            </button>
        );
    } else {
        return null;
    }
};