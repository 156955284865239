import {UseFormRegister} from "react-hook-form";
import React from "react";
import {ErrorMessage} from "./ErrorMessage";

export type CheckboxProperties = {
    name: string,
    label: string,
    value: string | number,
    validation: any,
    errors: any,
    register: UseFormRegister<any>,
    showErrors?: boolean,
    onCustomChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    isRadioCheckbox?: boolean
}

export const Checkbox = ({name, value, label, validation, errors, register, onCustomChange,
                             showErrors = false, isRadioCheckbox = false}: CheckboxProperties) => {
    const id = name + '_' + value;

    const { onChange, ...registerProps } = register(name, validation);
    return (
        <div className="form-check">
            <input
                id={id}
                name={name}
                value={value}
                type={isRadioCheckbox ? "radio" : "checkbox"}
                onChange={(event) => {
                    if (onCustomChange) {
                        const { value, checked } = event.target;
                        onCustomChange(value, checked);
                    }
                    return onChange(event);
                }}
                {...registerProps}
                aria-invalid={errors[name] ? "true" : "false"} />
            <div>
                <label htmlFor={id}>{label}</label>
                { showErrors && <ErrorMessage errors={errors} name={name} /> }
            </div>
        </div>
    )
}
