import React, {useState} from "react";
import {findDOMNode} from "react-dom";
import {useSelector} from "react-redux";
import {
    closeSimulationConfigModal,
    selectSimulationConfigModalState,
    selectModalStatus, createPrediction, isScenarioNameUsed, isPredictionNameUsed
} from "../../store/simulationSlice";
import {css} from "styled-components";
import {Modal} from "@kepler.gl/components";
import "./SimulationConfigModal.scss";
import {useForm, SubmitHandler} from "react-hook-form";
import {Input} from "../../../../components/form/Input";
import {SimulationParameters} from "../../models/SimulationParameters";
import {Checkbox} from "../../../../components/form/Checkbox";
import {ErrorMessage} from "../../../../components/form/ErrorMessage";
import {DEFAULT_NOTIFICATION_TOPICS, DEFAULT_NOTIFICATION_TYPES} from "@kepler.gl/constants";
import {createNotification} from "@kepler.gl/utils";
import {addNotification} from "@kepler.gl/actions";
import {TimeSlotOptions} from "../../models/TimeSlot";
import {useAppDispatch} from "../../../../hooks";
import {SimulationInputsFactory} from "../../services/SimulationInputsFactory";
import {selectProjectTypes, selectSelectedProjectType} from "../../../../components/user/userSlice";

const SimulationParametersValidation = {
    "name": {
        required: {value: true, message: "Please specify a simulation name."},
    },
    bicyclePercentage: {
        required: {value: true, message: "Please specify the percentage of bicycle traffic."},
        min: {value: 0, message: "Must be more than 0."},
        max: {value: 1, message: "Must be less than 1."},
    },
    eVehiclePercentage: {
        required: {value: true, message: "Please specify the percentage of e-vehicle traffic."},
        min: {value: 0, message: "Must be more than 0."},
        max: {value: 1, message: "Must be less than 1."},
    },
    dayType: {
        validate: (value) => value.length > 0 || "Select at least one.",
    },
    timeSlot: {
        validate: (value) => value.length > 0 || "Select at least one.",
    },
    rainfall: {
        required: {value: true, message: "Please specify the rainfall."},
        min: {value: 0, message: "Must be more than 0 millimeters."},
        max: {value: 200, message: "Must be less than 200 millimeters."},
    },
    seaLevelRise: {
        required: {value: true, message: "Please specify the sea level rise."},
        min: {value: 0, message: "Must be more than 0 meters."},
        max: {value: 5, message: "Must be less than 5 meters."},
    },
    riverDischarge: {
        required: {value: true, message: "Please specify the river discharge."},
        min: {value: 0, message: "Must be more than 0 cubic meters/second."},
        max: {value: 1000, message: "Must be less than 1000 cubic meters/second."},
    }
}

export const SimulationDataValidation = {
    agricultural_landuse: { min: 0.0, max: 240000.0 },
    commercial_landuse: { min: 0.0, max: 272000.0 },
    industrial_landuse: { min: 0.0, max: 363000.0 },
    natural_landuse: { min: 0.0, max: 320000.0 },
    residential_landuse: { min: 0.0, max: 542000.0 },
    food_pois: { min: 0, max: 64 },
    fun_pois: { min: 0, max: 58 },
    health_pois: { min: 0, max: 7 },
    infrastructure_pois: { min: 0, max: 418 },
    school_pois: { min: 0, max: 6 },
    services_pois: { min: 0, max: 28 },
    shop_pois: { min: 0, max: 108 },
    sport_pois: { min: 0, max: 23 },
    tourism_pois: { min: 0, max: 55 },
    static_population: { min: 0, max: 2101 },
    speed: { min: 5, max: 110 }
}

export const SimulationConfigModal = (props: any) => {
    const {rootNode} = props;
    const isConfigModalOpen = useSelector(selectSimulationConfigModalState)
    const dispatch = useAppDispatch();
    const visState = useSelector((state: any) => state?.undoRedo?.changes);
    const selectedProjectType = useSelector(selectSelectedProjectType);
    const projectTypes = useSelector(selectProjectTypes);
    const simulationStatus = useSelector(selectModalStatus);
    const {register, handleSubmit, setError, clearErrors, formState: {errors}} = useForm<SimulationParameters>(
        {defaultValues: new SimulationParameters()})
    const [isCheckingName, setIsCheckingName] = useState(false);
    const isProjectImmerseon = projectTypes[selectedProjectType].project === 'immerseon';

    const DefaultStyle = css`max-width: 600px;`;

    const onCancel = () => {
        dispatch(closeSimulationConfigModal());
    }

    const onSubmit: SubmitHandler<SimulationParameters> = async (data: SimulationParameters) => {
        try {
            const simulationInputs = SimulationInputsFactory.build(visState, data, isProjectImmerseon);
            setIsCheckingName(true);
            const isNameUsed = await dispatch(isPredictionNameUsed(data.name)).unwrap();
            if (isNameUsed) {
                setError("name", {
                    type: "manual",
                    message: "This simulation name is already used"
                });
                setIsCheckingName(false);
                return;
            }
            clearErrors("name");
            await dispatch(createPrediction({simulationInputs: simulationInputs, name: data.name})).unwrap();
            dispatch(closeSimulationConfigModal());
        } catch (e) {
            dispatch(addNotification(createNotification({
                type: DEFAULT_NOTIFICATION_TYPES.error,
                message: 'Unable to create simulation. Please check the console for more information.',
                topic: DEFAULT_NOTIFICATION_TOPICS.global
            })))
            console.error("Unable to create simulation", e);
        }
    }

    const timeSlotTemplate = [];
    for (let timeSlot in TimeSlotOptions) {
        const key = 'timeSlot' + timeSlot;
        const value = TimeSlotOptions[timeSlot];
        timeSlotTemplate.push(
            (<Checkbox showErrors={false} key={key} name="timeSlot" label={value} value={timeSlot}
                       validation={SimulationParametersValidation.timeSlot} errors={errors} register={register}/>)
        );
    }

    if (!rootNode) return null;
    return (
        <Modal
            title="Start New Simulation"
            parentSelector={() => findDOMNode(rootNode) as HTMLElement}
            isOpen={isConfigModalOpen}
            theme={null}
            cssStyle={DefaultStyle}
            onCancel={onCancel}
        >
            <form onSubmit={handleSubmit(onSubmit)} onReset={onCancel} className="citynexus-form">
                <div className="form-group">
                    <Input name="name" label="Simulation Name" validation={SimulationParametersValidation.name}
                           register={register} errors={errors}/>
                </div>
                <div className="form-group">
                    <Input name="bicyclePercentage" label="% Bicycles"
                           validation={SimulationParametersValidation.bicyclePercentage} type="number"
                           register={register} errors={errors} step={0.1} min={0} max={1}/>
                </div>
                <div className="form-group">
                    <Input name="eVehiclePercentage" label="% E-Vehicles"
                           validation={SimulationParametersValidation.eVehiclePercentage} type="number"
                           register={register} errors={errors} step={0.1} min={0} max={1}/>
                </div>
                <div className="form-group">
                    <div className="form-label">
                        Type of day
                        <ErrorMessage errors={errors} name="dayType"/>
                    </div>
                    <div className="form-control">
                        <Checkbox showErrors={false} name="dayType" label="Weekday (Mo - Fr)" value="weekday"
                                  validation={SimulationParametersValidation.dayType} errors={errors}
                                  register={register}/>
                        <Checkbox showErrors={false} name="dayType" label="Weekend (Sa - Su)" value="weekend"
                                  validation={SimulationParametersValidation.dayType} errors={errors}
                                  register={register}/>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-label">
                        Time Slot
                        <ErrorMessage errors={errors} name="timeSlot"/>
                    </div>
                    <div className="form-control">
                        {timeSlotTemplate}
                    </div>
                </div>
                {isProjectImmerseon && (
                    <>
                        <div className="form-group">
                            <Input name="rainfall" label="Rainfall in mm"
                                   validation={SimulationParametersValidation.rainfall} errors={errors}
                                   register={register} type="number"
                                   step={5} min={0} max={200} />
                        </div>
                        <div className="form-group">
                            <Input name="seaLevelRise" label="Sea Level Rise in m"
                                   validation={SimulationParametersValidation.seaLevelRise}
                                   errors={errors} register={register} type="number"
                                   step={.1} min={0} max={5} />
                        </div>
                        <div className="form-group">
                            <Input name="riverDischarge" label="River Discharge in m3/s"
                                   validation={SimulationParametersValidation.riverDischarge}
                                   errors={errors} register={register} type="number"
                                   step={10} min={0} max={1000} />
                        </div>
                    </>
                )
                }
                <div className="form-group">
                    <div className="form-actions">
                        <input className="button" type="reset" value="Cancel"/>
                        <input className="button" type="submit"
                               value={simulationStatus === 'idle' ? 'Start Simulation' : 'Saving...'}
                               disabled={simulationStatus !== 'idle'}/>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
