export const TimeSlotOptions = {
    0: '0 - 3h',
    3: '3 - 6h',
    6: '6 - 9h',
    9: '9 - 12h',
    12: '12 - 15h',
    15: '15 - 18h',
    18: '18 - 21h',
    21: '21 - 00h',
};
